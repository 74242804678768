import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="booking--magic"
export default class extends Controller {
  static targets = ["email", "mobile", "name", "date", "product"];
  static values = {
    productMatchers: Object
  }

  connect() {
    console.log(this.identifier);
  }

  didPaste(event) {
    this.splitByTabs(event.target);

    this.parseData(event.target);
  }

  splitByTabs(target) {
    const data = target.value;

    // Convert tabs to line breaks. Remove empty lines
    const split = data.replace(/\t/g, "\n")
    target.value = split;
  }

  parseData(target) {
    const data = target.value

    // Map to trim each line and then filter out empty lines
    const inputLines = data.split(/\r?\n/).map(line => line.trim()).filter(line => line !== "");

    let emailLine, phoneLine, nameLine, dateLine, productIndex;

    const ignoredWords = ['email', 'address', 'phone', 'number', 'name'];

    const validLines = inputLines.filter(line => {
      return !ignoredWords.some(word => line.toLowerCase().includes(word));
    });

    for (let line of validLines) {
      if (line.length < 4) {
        continue;
      }

      if (this.isPlausiblyDate(line)) {
        dateLine = line;
        continue;
      }

      // Check if the line matches a product matcher key
      if (this.productMatchersValue[line]) {
        productIndex = this.productMatchersValue[line];  // Set productIndex to the matching value (product ID)
        continue;
      }

      if (line.includes("@")) {
        emailLine = line;
      } else if (this.isPlausiblyPhoneNumber(line)) {
        phoneLine = line;
      } else {
        nameLine = line;
      }
    }


    if (emailLine) {
      this.emailTarget.value = emailLine;
    }

    if (phoneLine) {
      this.mobileTarget.value = phoneLine;
    }

    if (nameLine) {
      this.nameTarget.value = nameLine;
    }

    if (productIndex) {
      this.productTarget.value = productIndex
    }

    if (dateLine) {
      const parsedDate = this.parseDate(dateLine);
      if (parsedDate) {
        this.dateTarget.value = parsedDate.toISOString().split('T')[0]; // Set date in YYYY-MM-DD format
      }
    }
  }

  isPlausiblyDate(string) {
    // Regular expression to match the date pattern d/m/y
    // Regular expression to match date patterns in both d/m/XX and d/m/20XX formats
    const pattern = /\b\d{1,2}\/\d{1,2}\/(\d{2}|20\d{2})\b/;
    return pattern.test(string);
  }

  isPlausiblyPhoneNumber(string) {
    //length > 8
    //more than half of string is digits
    return string.length > 8 && string.replace(/[^\d]/g, '').length > string.length / 2
  }

  parseDate(string) {
    const today = new Date();
    const threeMonthsFromToday = new Date(today);
    threeMonthsFromToday.setMonth(today.getMonth() + 3);

    const parts = string.split('/');
    if (parts.length !== 3) return null;

    let day, month, year;

    // Get the user's locale
    const userLocale = navigator.language || navigator.userLanguage;

    // Check if the locale is US-based
    const isUSLocale = userLocale.startsWith('en-US');

    if (isUSLocale) {
      // American style MM/DD/YY or MM/DD/YYYY
      month = parseInt(parts[0], 10);
      day = parseInt(parts[1], 10);
    } else {
      // Sane style DD/MM/YY or DD/MM/YYYY
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10);
    }

    year = parseInt(parts[2], 10);
    if (year < 100) year += 2000; // Convert 2-digit year to 20XX

    // Create the date in UTC to avoid timezone issues
    const parsedDate = new Date(Date.UTC(year, month - 1, day));

    // Check if the parsed date is valid and within 3 months from today
    if (this.isValidDate(parsedDate, today, threeMonthsFromToday)) {
      return parsedDate;
    }

    return null;
  }
  isValidDate(date, today, threeMonthsFromToday) {
    // Ensure the date is valid, and is within 3 months of today
    return date instanceof Date && !isNaN(date) &&
      date >= today && date <= threeMonthsFromToday;
  }
}

