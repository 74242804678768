import { Controller } from "@hotwired/stimulus"

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

// Connects to data-controller="gallery"
export default class extends Controller {
  static values = {
    inline: { type: Boolean, default: false },
    uuid: String,
    paid: Boolean
  }

  //Container and items are used for inline controller
  //https://github.com/sachinchoolur/lightGallery/issues/1536
  static targets = ["container", "items"]

  connect() {
    console.log(this.identifier);

    if (this.inlineValue) {

      this.gallery = lightGallery(this.itemsTarget, {
        licenseKey: "63B20EC2-C8FA-405E-8363-5B3FA028D2AC",
        container: this.containerTarget,
        customSlideName: true,
        closable: false,
        download: this.paidValue,
        showMaximizeIcon: true,
        galleryId: "photo",
        plugins: [lgZoom, lgThumbnail],//not including hash as this will always change the url to show a slide
        mobileSettings: {
          controls: false,
          showCloseIcon: false,
          download: this.paidValue,
          rotate: false
        }
      });


      this.gallery.openGallery();

      window.gallery = this.gallery;
    } else {
      this.gallery = lightGallery(this.element, {
        licenseKey: "63B20EC2-C8FA-405E-8363-5B3FA028D2AC",
        customSlideName: true,
        autoplayFirstVideo: false,
        pager: false,
        galleryId: "photo",
        download: this.paidValue,
        plugins: [lgZoom, lgThumbnail],
        mobileSettings: {
          controls: false,
          showCloseIcon: true,
          download: this.paidValue,
          rotate: false
        }
      });
      this.openSelectedSlide(this.gallery);
    }
  }

  resetFilmstripPositions() {
    //refresh on resize makes gallery fix up the filmstrip positioning
    //Without this, the currently active thumbnail is on the hard left
    //the method doesn't work until the tab is active (presumably gallery visible)

    if (this.gallery) {
      this.gallery.refreshOnResize();
    }
  }

  openSelectedSlide(gallery) {
    if (!this.hasUuidValue) {
      return
    }

    const slides = this.element.querySelectorAll('.thumbnail-preview');

    // Find the index of the first slide that matches the UUID
    const slideIndex = Array.from(slides).findIndex(slide => slide.getAttribute('data-slide-name') === this.uuidValue);

    // If a matching slide is found, open the gallery at that slide
    if (slideIndex !== -1) {
      gallery.openGallery(slideIndex);
    }
  }

  nonZeroParentWidth(element) {
    let currentElement = element;

    while (currentElement) {
      const parentElement = currentElement.parentElement;

      // If there's no parent, return null or 0 (whatever makes sense for your use case)
      if (!parentElement) {
        return 0; // Or return null;
      }

      const width = parentElement.offsetWidth;

      // If width is not zero, return it
      if (width !== 0) {
        return width;
      }

      // Move to the next parent
      currentElement = parentElement;
    }

    return 0; // In case no parent has a non-zero width
  }

}