import Trix from "trix"

document.addEventListener("trix-initialize", function (event) {
  function insertToolbarButton(event) {
    const { toolbar, attachment } = event;

    // Add custom link button
    const linkButtonHTML = `
      <button 
        type="button" 
        title="Link" 
        class="trix-button trix-button--link" 
        data-attachment-id="${attachment.id}"
      >
        Link
      </button>
    `;

    toolbar
      .querySelector(".trix-button-group")
      .insertAdjacentHTML("beforeend", linkButtonHTML);

    // Add event listener for the custom link button
    toolbar
      .querySelector(`.trix-button--link[data-attachment-id="${attachment.id}"]`)
      .addEventListener("click", function () {
        handleLinkAttachment(attachment);
      });
  }

  function handleLinkAttachment(attachment) {
    const url = prompt("Enter the URL: (blank to remove)", attachment.getAttributes().href || "https://");

    // Explicitly set href to null if the URL is empty or only whitespace
    attachment.setAttributes({ href: url.trim() === "" ? null : url.trim() });

    const editor = document.querySelector("trix-editor").editor;
    editor.insertAttachment(attachment); // Reinsert attachment to update it
  }

  event.target.addEventListener("trix-attachment-before-toolbar", insertToolbarButton, { once: false });
});


