import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ff-pay--stripe"
export default class extends Controller {
  static values = {
    jumpLink: String,
    publishableKey: String
  }

  connect() {
    console.log(this.identifier);
    this.stripe = Stripe(this.publishableKeyValue);
  }

  async initializeCheckout(upgradeId) {
    console.log("Initialise")

    const url = new URL(`/ff_pay/stripe/j/${this.jumpLinkValue}/session`, window.location.origin);
    url.searchParams.append("upgrade_id", upgradeId);
    const response = await fetch(url.toString(), { method: "POST" });

    const { clientSecret } = await response.json();

    this.checkout = await this.stripe.initEmbeddedCheckout({
      clientSecret,
    });

    this.checkout.mount('#checkout');
  }

  showCheckout(event) {
    console.log("showCheckout")
    //send event to show the stripe modal controller
    this.dispatch("show-stripe-modal", { detail: {} })

    const upgradeId = event.detail?.upgrade.id
    if (!upgradeId) {
      console.error("No upgrade id provided in showCheckout")
    }

    if (!this.checkout) {
      this.initializeCheckout(upgradeId);
    }

    if (typeof gtag === "function") {
      gtag("event", "begin_checkout", {
        currency: "GBP",
        value: 100,
        items: [
          {
            item_id: "unlock_01",
            item_name: "Unlock Video"
          }
        ]
      });
    } else {
      console.warn("gtag is not defined");
    }
  }
}
