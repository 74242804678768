import { Controller } from "@hotwired/stimulus"

//https://blog.corsego.com/datarangepicker-js-ransack
// Connects to data-controller="date-range-picker"
export default class extends Controller {
  connect() {
    console.log(this.identifier);

    const picker = new DateRangePicker(this.element, {
      ranges: this.ranges(),
      alwaysShowCalendars: true,
      showCustomRangeLabel: false,
      locale: {
        format: "YYYY/MM/DD",
        separator: " - "
      }
    }, (start, end) => { // Changed to an arrow function

      // Small delay to let lib change current value
      setTimeout(() => {
        this.hideMagicAllText()
        this.submit();
      }, 100);
    }
    )

    this.hideMagicAllText()
  }

  submit() {
    this.element.form.submit();
  }

  ranges() {
    let ranges = {
      'Today': [moment().startOf('day'), moment().endOf('day')],
      'Tomorrow': [moment().add(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day')],
      'All': [moment("2020-01-01"), moment("2040-01-01")],
      'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      'This Year': [moment().startOf('year'), moment().endOf('year')]
    };

    // Add the last 3 months
    for (let i = 1; i >= -1; i--) {
      let startOfMonth = moment().subtract(i, 'months').startOf('month').startOf('day');
      let endOfMonth = moment().subtract(i, 'months').endOf('month').endOf('day');
      let key = startOfMonth.format('MMMM-YYYY'); // Use startOfMonth for formatting to avoid mutation issues
      ranges[key] = [startOfMonth, endOfMonth];
    }



    return ranges
  }

  // Special case where we have selected the 'all' range, then hide it.
  hideMagicAllText() {
    const dateInput = this.element

    if (dateInput.value === "2020/01/01 - 2040/01/01") {
      dateInput.style.color = 'transparent';
    } else {
      dateInput.style.color = ''; // Reset to default color if value changes
    }
  }
}
